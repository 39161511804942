import React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Card, Col, Row, Accordion, ListGroup, ListGroupItem} from "react-bootstrap";
import elkeImg from "../images/team/ÄrztinDrElkeHeinitz.jpg";
import ankeImg from "../images/team/ÄrztinDrAnkeHumke.jpg";
import michaelImg from "../images/team/ArztDrMichaelTeilken.jpg";
import lianeImg from "../images/team/MedizinischeFachangestellteLianeSchöning.jpg";
import siljaImg from "../images/team/MedizinischeFachangestellteSiljaLange.jpg";
import jessicaImg from "../images/team/MedizinischeFachangestellteJessicaLühr.jpg";
import benjaminImg from "../images/team/BenjaminSchmidt.jpg";
import downloadFile from '../files/flyer_idana.pdf'

export const query = graphql`
query {
  child: file(relativePath: {eq: "kinder.jpg"}) {
    childImageSharp {
      fluid(maxHeight: 300, fit: COVER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  heart: file(relativePath: {eq: "heart.jpg"}) {
    childImageSharp {
      fluid(maxHeight: 300, fit: COVER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
    sport: file(relativePath: {eq: "sport.jpg"}) {
    childImageSharp {
      fluid(maxHeight: 300, fit: COVER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
     psycho: file(relativePath: {eq: "psycho.jpg"}) {
    childImageSharp {
      fluid(maxHeight: 300, fit: COVER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}


`
export default class ServicesPage extends React.Component {
    constructor(props) {
        super(props)

    }


    render() {
        const links = [
            {
                "name": "Patientenaufnahme & Datenschutz",
                "links": [
                    {"name": "Versicherungsdaten", "link": "https://idana.app/ZBMGVC"},
                    {"name": "Datenschutz", "link": "https://idana.app/WPZZ65"},
                    {"name": "Einwilligung Videosprechstunde", "link": "https://idana.app/F8X3W0"}
                ]
            },
            {
                "name": "Covid 19",
                "links": [
                    {"name": "PCR-Abstrich Covid-19", "link": "https://idana.app/GSKS9E"},
                    {"name": "Covid-19 Schutzimpfung (alle Impfstoffe)", "link": "https://idana.app/MBCVJF"},
                    {
                        "name": "Covid-19 Schutzimpfung  mit mRNA Impfstoff(Biontech/Moderna)",
                        "link": "https://idana.app/TN5U4D"
                    },
                    {
                        "name": "Covid-19 Auffrisch-/Boosterimpfung mit mRNA Impfstoff(Biontech/Moderna)",
                        "link": "https://idana.app/Z6KZ78"
                    },
                    {
                        "name": "Covid-19 Impfpriorisierung für Auffrisch-/Boosterimpfung",
                        "link": "https://idana.app/4Y5TWF"
                    },
                    {
                        "name": "Einwilligung Datenübermittlung Covid-19 Impfzertifikat",
                        "link": "https://idana.app/JXFC16"
                    },
                    {"name": "Impfnebenwirkungen / Behandlung Covid-19 ", "link": "https://idana.app/ANC6PM"}
                ]
            },
            {
                "name": "Allergien & Unverträglichkeiten",
                "links": [
                    {"name": "Allergien & Unverträglichkeiten", "link": "https://idana.app/JRMFGS"},
                ]
            },
            {
                "name": "Diabetes",
                "links": [
                    {"name": "Diabetes-Anamnese", "link": "https://idana.app/BXFARA"},
                ]
            },
            {
                "name": "Impfungen",
                "links": [
                    {"name": "Aktueller Impfstatus/Einwilligung in Impfung", "link": "https://idana.app/GW5SD0"},
                    {"name": "Grippeschutz", "link": "https://idana.app/DJJV7Y"},
                ]
            },
            {
                "name": "Lunge",
                "links": [
                    {"name": "Asthma", "link": "https://idana.app/960T16"},
                    {"name": "COPD", "link": "https://idana.app/MANARY"},
                ]
            },
            {
                "name": "Gesundheit",
                "links": [
                    {"name": "Check-Up für alle ab 35 Jahre (alle 3 Jahre)", "link": "https://idana.app/Y0LFG4"}
                ]
            },
            {
                "name": "Herz",
                "links": [
                    {"name": "Herz", "link": "https://idana.app/9WRF0B"}
                ]
            },
            {
                "name": "Orthopädie",
                "links": [
                    {"name": "Aufnahmebogen", "link": "https://idana.app/9ALQ8Y"}
                ]
            },
            {
                "name": "Psyche",
                "links": [
                    {"name": "Lebensqualität", "link": "https://idana.app/A9ZZK2"},
                    {"name": "Schlafanamnese", "link": "https://idana.app/MTMNNK"},
                    {"name": "Schmerzbelastung", "link": "https://idana.app/FK7ABS"},
                    {"name": "Stressbelastung", "link": "https://idana.app/TKPR6Z"},
                    {"name": "Zigarettenabhängigkeit", "link": "https://idana.app/03D349"},
                ]
            },
        ]

        return (
            <Layout>
                <SEO title="Checkin"/>

                <section className="page-section" id="services">
                    <div className="container">
                        <h1 className="text-center mt-0">Check-In Fragebögen</h1>
                        <hr className="divider my-4"/>
                        <div className="row justify-content-center">
                            <div className="col-lg-8 text-center">
                                <p className="mb-4">
                                    Mit unseren Check-In Fragebögen können Sie uns bereits
                                    vor dem Praxisbesuch wertvolle Informationen für Ihre Behandlung zukommen lassen. Es
                                    ist ganz einfach. Klicken Sie den passenden Begriff an und es öffnet sich der
                                    entsprechende Fragebogen. Bitte füllen Sie den Fragebogen nach bestem Wissen aus und
                                    unterschreiben mit der Maus auf dem PC oder mit dem Finger auf dem Smartphone. Mit
                                    Absenden wird der fertig ausgefüllte Fragebogen automatisch an unserer
                                    Praxissoftware gesendet. Probieren Sie es aus! Die verschlüsselte Übermittlung der
                                    Daten erfolgt EU-datenschutzkonform über einen deutschen Server.
                                    <br/>
                                    <br/>
                                    Weitere Informationen zu den Fragebögen von Idana finden sie <a href={downloadFile} download>hier</a>.
                                </p>

                            </div>
                        </div>

                        <Row xs={1} md={8}  className={"g-4 justify-content-center"}>

                            <Accordion defaultActiveKey="0">
                                {links.map((link, idx) => {

                                    return <Accordion.Item key={idx} eventKey={idx}>
                                        <Accordion.Header>{link.name}</Accordion.Header>
                                        <Accordion.Body>
                                            <ListGroup>


                                            {link.links.map((l, idy) => {
                                                return <ListGroupItem  action href={l.link} target={"_blank"} className={"btn btn-primary"} key={idy}>{l.name} </ListGroupItem>
                                            })}
                                                </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                })}
                            </Accordion>
                        </Row>
                    </div>
                </section>


            </Layout>
        )
    }
}

